import {
  Alert,
  Button,
  Grid,
  Card,
  TextField,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import { useState, useEffect } from "react";
import { storage, db } from "../firebase";
import {
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  getFirestore,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../contexts/AuthContext";
import ReactAudioPlayer from "react-audio-player";
import AudioTranscriptions from "./AudioTranscriptions";
import { CircularProgress } from "@mui/material";

export default function Upload() {
  const { userId } = useAuth();
  const [fileUpload, setFileUpload] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState("");
  const [audioNames, setAudioNames] = useState({});
  const [tempAudioName, setTempAudioName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transcriptionText, setTranscriptionText] = useState("");
  const [audioUploads, setAudioUploads] = useState([]);
  const [userTranscriptions, setUserTranscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const db = getFirestore();

  const upload = async () => {
    if (fileUpload == null) return;
    if (!fileUpload.type.startsWith("audio/")) {
      setError("Please upload only audio files.");
      return;
    }

    const audioFileId = uuidv4(); // Generate a unique audio file ID
    const fileRef = ref(
      storage,
      `audio/${userId}/${fileUpload.name}-${audioFileId}`
    );

    try {
      const snapshot = await uploadBytes(fileRef, fileUpload);
      console.log(`Snapshot 2: ${JSON.stringify(snapshot, null, 2)}`);
      const url = await getDownloadURL(snapshot.ref);

      //Prepare Audio file data for Firestore
      const newFile = {
        name: tempAudioName || fileUpload.name,
        url,
        userId,
        uploadDate: new Date(),
        size: fileUpload.size,
      };

      // Save to Firestore in the audioFiles collection
      const audioDocRef = doc(db, `audioFiles/${audioFileId}`);
      setDoc(audioDocRef, newFile);

      //Update the fileList
      setFileList((prevList) => [...prevList, newFile]);

      // Update the user's document in the users collection
      const userDocRef = doc(db, `users/${userId}`);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const updatedAudioIds = userData.audioUploads
          ? [...userData.audioUploads, audioFileId] // Store the ID
          : [audioFileId];
        await setDoc(
          userDocRef,
          { ...userData, audioUploads: updatedAudioIds },
          { merge: true }
        );
        setAudioUploads(updatedAudioIds); // Update the state
      }

      // Process and upload transcription data
      const transcriptionFilePath = `${url}.wav_transcription.txt`; // The transcription file path

      try {
        const transcriptionResponse = await fetch(transcriptionFilePath);
        const transcriptionData = await transcriptionResponse.json();

        const processedTranscription = transcriptionData.results.reduce(
          (acc, curr) => {
            const transcriptText = curr.alternatives[0].transcript;
            acc.fullTranscript += transcriptText + " "; // Concatenate for full transcript

            acc.sentence.push({
              transcript: transcriptText,
              resultEndTime: curr.resultEndTime,
            });
            acc.completionDuration = curr.resultEndTime;
            return acc;
          },
          { fullTranscript: "", sentences: [], completionDuration: "" }
        );

        //Save the processed data to Firestore under the user's 'transcripts' subcollection
        const transcriptDocRef = doc(
          collection(db, `users/${userId}/transcripts`)
        );
        await setDoc(transcriptDocRef, {
          ...processedTranscription,
          audioFileId: audioFileId, // Reference to the audio file this transcript belongs to
          createdAt: new Date(), // Timestamp when this transcipt is created
        });
      } catch (error) {
        console.log("Error fetching or processing transcription data:", error);
      }

      // const audioDocSnap = await getDoc(audioDocRef);
      // if (audioDocSnap.exists()) {
      //   const audioData = audioDocSnap.data();
      //   console.log(`AUDIO DOC REF: ${JSON.stringify(audioData, null, 2)}`);
      //   const transcriptionFilePath = `${
      //     audioData.url?.split("?")[0]
      //   }.wav_transcription.txt?${audioData.url?.split("?")[1]}`;
      //   console.log(`UPLOAD TRANSCRIPT FILE PATH: ${transcriptionFilePath}`);

      //   try {
      //     const response = await fetch(transcriptionFilePath);
      //     console.log(response);
      //     console.log(`RESPONSE: ${JSON.stringify(response, null, 2)}`);
      //     const contentType = response.headers.get("content-type");
      //     console.log({ contentType });
      //     const data = await response.json();
      //     console.log(data);
      //     if (contentType && contentType.includes("application/json")) {
      //       const { results } = await response.json();
      //       console.log(results);
      //       const transcripts = results?.map((obj) => {
      //         let text = "";
      //         text.push(obj.alternatives[0].transcript);
      //         console.log(`TSCRPT TEXT: ${text}`);
      //         return text;
      //       });
      //       console.log(transcripts);
      //       // Here, you would update the state with the transcripts
      //     } else {
      //       const rawTextData = await response.text();
      //       console.log("Raw data:", rawTextData);
      //       // Handle raw text data as needed
      //     }
      //   } catch (error) {
      //     console.error("Error fetching data:", error);
      //   }
      // }

      // Reset state after upload
      setFileUpload(null);
      setTempAudioName("");
    } catch (error) {
      console.error("Error in file upload or processing:", error);
      // Handle the error appropriately
      setError("An error occurred during the file upload.");
    }
  };

  // Event handler for deleting a file
  const handleDeleteFile = async (fileId) => {
    try {
      // Fetch the audio file document from Firestore to get the URL
      const audioDocRef = doc(db, `audioFiles/${fileId}`);
      const audioDocSnap = await getDoc(audioDocRef);

      if (audioDocSnap.exists()) {
        const { url } = audioDocSnap.data();

        // Decode the URL to get a more manipulable form
        let decodedUrl = decodeURIComponent(url);
        // Extract the path between '/o/' and '?alt='
        let pathStart = decodedUrl.indexOf("/o/") + 3;
        let pathEnd = decodedUrl.indexOf("?alt=");
        let storagePath = decodedUrl.substring(pathStart, pathEnd);
        console.log(storagePath);

        // Replace '%2F' (URL encoding for '/') with '/' to get the correct path
        storagePath = storagePath.replace(/%2F/g, "/");

        // Delete the audio file from Storage
        const audioFileRef = ref(storage, storagePath);
        console.log(audioFileRef);
        await deleteObject(audioFileRef);

        const transcriptionFilePath = `${storagePath}.wav_transcription.txt`;
        console.log(transcriptionFilePath);
        const transcriptionFileRef = ref(storage, transcriptionFilePath);
        console.log(transcriptionFileRef);
        await deleteObject(transcriptionFileRef);

        // Delete the document from Firestore
        await deleteDoc(audioDocRef);
      }

      setFileList(fileList.filter((file) => file.id !== fileId));
    } catch (error) {
      console.error("Error deleting file:", error);
      setError("Failed to delete file. Please try again.");
    }
    // deleteDoc(audioDocRef);

    // // Update the user's document to remove the file ID from 'audioUploads'
    // const userDocRef = doc(db, `users/${userId}`);
    // getDoc(userDocRef).then((docSnap) => {
    //   if (docSnap.exists()) {
    //     const userData = docSnap.data();
    //     const updatedAudioUploads = userData.audioUploads.filter(
    //       (id) => id !== fileId
    //     );
    //     setDoc(
    //       userDocRef,
    //       { ...userData, audioUploads: updatedAudioUploads },
    //       { merge: true }
    //     );
    //   }
    // });

    // // Update local fileList state
    // setFileList(fileList.filter((file) => file.id !== fileId));
  };

  useEffect(() => {
    if (userId) {
      // Fetch audio files from Firebase Storage
      const userAudioListRef = ref(storage, `audio/${userId}`);
      listAll(userAudioListRef).then((res) => {
        Promise.all(
          res.items.map((itemRef) =>
            getDownloadURL(itemRef).then((url) => ({ url, ref: itemRef }))
          )
        ).then((files) => {
          setFileList(files);
        });
      });

      // Fetch user's audioUploads from Firestore
      const userDocRef = doc(db, `users/${userId}`);
      getDoc(userDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setAudioUploads(userData.audioUploads || []);
        }
      });
    }
  }, [userId]);

  const handleOpenModal = async (file) => {
    // console.log("File object:", file); // Debugging: Log the file object
    // console.log(`"File Split 0:${file.url?.split("?")[0]}`);

    setIsLoading(true);
    const transcriptionFilePath = `${
      file.url?.split("?")[0]
    }.wav_transcription.txt?${file.url?.split("?")[1]}`;
    console.log(`AUDIO PATH: ${transcriptionFilePath}`);
    try {
      const response = await fetch(transcriptionFilePath);
      console.log(response);
      const contentType = response.headers.get("content-type");
      console.log({ contentType });
      // Check if the content is JSON
      if (contentType && contentType.includes("application/json")) {
        const { results } = await response.json();
        console.log(`RESULTS: ${results}`);
        const transcripts = results?.map((obj) => {
          let text = [];

          text.push(obj.alternatives[0].transcript);
          return text;
        });
        console.log(transcripts);
        setTranscriptionText(transcripts);
        // Opening the modal
        setIsModalOpen(true);
      } else {
        // Handle other content types (binary, text, etc.) as needed
        const rawTextData = await response.text();
        console.log("Raw data:", rawTextData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTranscriptionText("Failed to load transcription.");
    }
    setIsLoading(false);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="Upload">
      {error && <Alert severity="error">{error}</Alert>}
      <Typography>UPLOAD AUDIO</Typography>
      <input
        type="file"
        onChange={(e) => {
          setFileUpload(e.target.files[0]);
          setTempAudioName("");
        }}
      />
      {fileUpload && (
        <>
          <TextField
            label="Audio Name"
            variant="outlined"
            value={tempAudioName}
            onChange={(e) => setTempAudioName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button onClick={upload}>Upload</Button>
        </>
      )}

      <AudioTranscriptions
        audioUploads={audioUploads}
        onDeleteFile={handleDeleteFile}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
}
