import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Button,
  Typography,
  Modal,
  Box,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReactAudioPlayer from "react-audio-player";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";

const AudioTranscriptions = ({ audioUploads, onDeleteFile }) => {
  const [fileDetails, setFileDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingTranscription, setIsLoadingTranscription] = useState(false);
  const [transcriptionText, setTranscriptionText] = useState("");
  const [currentFile, setCurrentFile] = useState(null);

  const { userId } = useAuth();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // console.log(`AUDIO UPLOADS in AT: ${audioUploads}`);
  useEffect(() => {
    (async () => {
      if (audioUploads && Array.isArray(audioUploads)) {
        const fetchFileDetails = await Promise.all(
          audioUploads.map(async (audioFileId) => {
            const audioDocRef = doc(db, `audioFiles/${audioFileId}`);
            const docSnap = await getDoc(audioDocRef);
            return docSnap.exists()
              ? { ...docSnap.data(), id: audioFileId }
              : null;
          })
        );

        Promise.all(fetchFileDetails).then((details) => {
          setFileDetails(details.filter((detail) => detail !== null));
        });
      }
    })();
  }, [audioUploads]);
  // console.log(`FILE DETAILS(In AT): ${JSON.stringify(fileDetails, null, 2)}`);

  const fetchTranscription = async (file) => {
    setCurrentFile(file);
    console.log(`FILE: ${JSON.stringify(file, null, 2)}`);
    setIsLoadingTranscription(true);
    const transcriptionFilePath = `${
      file.url?.split("?")[0]
    }.wav_transcription.txt?${file.url?.split("?")[1]}`;
    try {
      const response = await fetch(transcriptionFilePath);
      // const data = await response.json(); // or .json() based on your response type
      const contentType = response.headers.get("content-type");

      // Check if the content is JSON
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();

        // Prepare sentences with their endTime
        const sentences = data.results.map((result) => ({
          endTime: result.resultEndTime,
          transcript: result.alternatives[0].transcript,
        }));

        // Construct the full transcript text
        const fullTranscript = data.results
          .map((results) => results.alternatives[0].transcript)
          .join(" ");

        const newTranscriptionEntry = {
          name: file.name,
          uid: userId,
          createdAt: new Date(),
          transcript: {
            sentences: sentences,
            fullEndTime: data.results[data.results.length - 1].resultEndTime,
            fullTranscript: fullTranscript,
          },
        };

        // Save Transcript to firestore
        const userTranscriptsRef = doc(
          db,
          `users/${userId}/transcripts/${file.id}`
        );

        const docSnap = await getDoc(userTranscriptsRef);

        if (!docSnap.exists()) {
          await setDoc(userTranscriptsRef, newTranscriptionEntry);
          console.log("Transcription data saved successfully");
        } else {
          console.log(
            "Transcription data already exists. Skipping duplicate save."
          );
        }
        //Update UI or state as necessary
        setTranscriptionText(fullTranscript);
        setIsLoadingTranscription(false);
        handleOpenModal(file); // Open the modal after fetching
      } else {
        // Handle other content types (binary, text, etc.) as needed
        const rawTextData = await response.text();
        console.log("Raw data:", rawTextData);
        setTranscriptionText(
          "The transcription data is not in a supported format."
        );
        setIsLoadingTranscription(false);
      }
    } catch (error) {
      console.error("Error fetching transcription:", error);
      setTranscriptionText("Failed to load transcription.");
      setIsLoadingTranscription(false);
    }

    console.log(userId);
  };

  const TranscriptionModal = ({
    currentFile, // Add this line
    isModalOpen,
    handleCloseModal,
    transcriptionText,
    isLoadingTranscription,
    saveEditedTranscription, // Use this directly if passed as prop, or define function inside if not
  }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [editableTranscription, setEditableTranscription] = useState("");

    useEffect(() => {
      setEditableTranscription(transcriptionText);
    }, [transcriptionText, isModalOpen]);

    const { userId } = useAuth();

    // Copy Transcription Text
    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(transcriptionText);
        console.log("Transcription copied to clipboard");
      } catch (error) {
        console.error("Failed to copy:", error);
      }
    };

    const toggleEditMode = () => {
      setIsEditMode(!isEditMode);
    };

    const savedEditedTranscription = async () => {
      if (!currentFile) {
        console.error("No file selected for editing");
        return;
      }
      // Assuming currentFile.id is valid and corresponds to the Firestore document
      const transcriptDocRef = doc(
        db,
        `users/${userId}/transcripts/${currentFile.id}`
      );

      try {
        await setDoc(
          transcriptDocRef,
          {
            fullTranscript: editableTranscription,
          },
          { merge: true }
        );
        setTranscriptionText(editableTranscription);
        setIsEditMode(false); // Exit edit mode after saving
        console.log("Transcription updated successfully");
      } catch (error) {
        console.error("Error updating transcription", error);
      }
    };

    return (
      <Modal
        open={isModalOpen}
        onClose={() => {
          handleCloseModal();
          setIsEditMode(false);
        }}
        aria-labelledby="transcription-modal"
        aria-describedby="shows-transcription-of-audio"
      >
        <Box sx={modalStyle}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Transcription
            </Typography>
            <IconButton onClick={copyToClipboard} aria-label="copy">
              <ContentCopyIcon />
            </IconButton>
          </Box>
          {isLoadingTranscription ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <CircularProgress />
            </Box>
          ) : isEditMode ? (
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={editableTranscription}
              onChange={(e) => setEditableTranscription(e.target.value)}
              sx={{ mt: 2 }}
            />
          ) : (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {transcriptionText}
            </Typography>
          )}
          <Box display="flex" justifyContent="flex-end" mt={2}>
            {isLoadingTranscription ? null : isEditMode ? (
              <>
                <Button onClick={savedEditedTranscription} color="primary">
                  Save
                </Button>
                <Button onClick={() => setIsEditMode(false)} color="secondary">
                  Cancel
                </Button>
              </>
            ) : (
              <Button onClick={toggleEditMode} color="primary">
                Edit
              </Button>
            )}
          </Box>
        </Box>
        {/* //   <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        //     {isLoadingTranscription ? (
        //       <div style={{ display: "flex", alignItems: "center" }}>
        //         <CircularProgress size={20} style={{ marginRight: "10px" }} />
        //         Loading transcription...
        //       </div>
        //     ) : (
        //       transcriptionText
        //     )}
        //   </Typography>
        // </Box> */}
      </Modal>
    );
  };
  return (
    <>
      <Grid container spacing={1} style={{ marginTop: "20px" }}>
        {fileDetails.length > 0 ? (
          fileDetails.map((file, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  minWidth: 400,
                  boxShadow: 3,
                  padding: "1rem",
                }}
              >
                <Typography variant="body1" style={{ margin: "10px" }}>
                  {file.name}
                </Typography>
                <ReactAudioPlayer src={file.url} controls />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onDeleteFile(file.id)}
                  style={{ margin: "10px" }}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => fetchTranscription(file)}
                  style={{ margin: "10px" }}
                >
                  Show Transcription
                </Button>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography>No audio files found</Typography>
        )}
      </Grid>
      <TranscriptionModal
        currentFile={currentFile}
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        transcriptionText={transcriptionText}
        isLoadingTranscription={isLoadingTranscription}
        setIsLoadingTranscription={setIsLoadingTranscription} // Only if you need to change this state from within the modal
      />
    </>
  );
};

export default AudioTranscriptions;
